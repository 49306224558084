import React, { ReactNode, useState, useEffect } from 'react';
import './App.scss';
import { Paper, Box, Typography,  Grid, Palette, ButtonProps, SxProps, Button } from '@mui/material';

import { Link as ScrollLink } from 'react-scroll';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { useColorFromThemePalette } from './Helpers';
import RegistrationForm from './components/registrationForm/RegistrationForm';
import {  scroller } from 'react-scroll';


const pageWidth = 1170;
const registrierungAnchor = "registrierungAnchor"

const Navigation = (props: { color?: string, justifyContent: string, children: ReactNode }) => {
  const { justifyContent, children,color } = props;
  return (<Box sx={{ backgroundColor: color ? color : "#1f4034", padding: '0 1rem' }}>
    <Grid container justifyContent={justifyContent} sx={{ maxWidth: pageWidth + 'px', margin: '0 auto', padding: '1rem 0' }}>
      {children}
    </Grid>
  </Box>
  )
}

const ScrollIndicator = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [atBottom, setAtBottom] = useState(false);

  const indicatorHeight = 140;
  //console.log('atBottom', atBottom);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > window.innerHeight) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }

      if (((window.innerHeight + Math.round(window.scrollY)) >= document.body.offsetHeight - indicatorHeight) && (pageWidth > window.innerWidth - indicatorHeight)) {
        setAtBottom(true)
      } else {
        setAtBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <Paper
      className={`scroll-indicator ${isVisible ? 'visible' : ''}`}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }}
      sx={{ marginBottom: atBottom ? '4.5rem' : '1rem' }}
    >
      <ScrollLink
        to="home"
        spy={true}
        smooth={true}
        duration={500}
      >
        <ArrowUpwardIcon />
      </ScrollLink>
    </Paper>
  );
};

const HeaderNavigation = () => {
  return (
    <Navigation justifyContent="space-between">
      <Grid item sm={true} xs={12} display="flex" sx={{ alignItems: { xs: 'center' }, marginBottom: { sm: '0', xs: '2rem' } }}>
        <img
          src="./images/logo.png"
          alt="Logo"
          style={{ maxWidth: '100%', maxHeight: '60px' }}
        />
      </Grid>
      <Grid item sm={true} xs={12} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
        <nav>
          <ul>
            <li>
              <ScrollLink
                to="anmelden"
                spy={true}
                smooth={true}
                duration={500}
              >
                Anmelden
              </ScrollLink>
            </li>
          </ul>
        </nav>
      </Grid>
    </Navigation>
  );
}

const FooterNavigation = () => {
  return (
    <Navigation color={'black'} justifyContent="flex-end">
      <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
        <nav>
          <ul>
            <li><a href="https://podcast.apella.de/impressum" target="_blank">Impressum</a></li>
            <li><a href="https://podcast.apella.de/datenschutzhinweise" target="_blank">Datenschutzhinweise</a></li>
          </ul>
        </nav>
      </Grid>
    </Navigation>
  );
}

interface StyledIconButtonProps extends ButtonProps {
  icon: React.ReactElement,
  text: string
}

interface StyledBoxProps {
  imgSrc: string,
  title: string,
  md?: number,
  xs?: number,
  children: ReactNode
}

interface ContainedSectionProps {
  id?: string,
  children?: ReactNode,
  colorName?: keyof Palette,
  color?: string,
  slopedBottom?: boolean,
  slopedTop?: boolean
  sx?: SxProps,
  siblingHasSlopedBottom?: boolean
}

interface ContainedImgSectionProps {
  maxHeight?: number,
  img?: string,
  slopedBottom?: boolean,
  slopedTop?: boolean
}


const StyledBox = (props: StyledBoxProps) => {
  const { imgSrc, title, md, xs, children } = props;
  return (
    <Grid item md={md} xs={xs} sx={{ display: 'flex', justifyContent: 'flex-start;', alignContent: 'space-around', alignItems: 'center', flexDirection: 'column', textAlign: 'center', marginBottom: { xs: '2rem' } }}>
      <Box component="img" src={imgSrc} alt="test" sx={{ maxWidth: '150px', height: 'auto' }} />
      <Typography color="primary" variant="h3" >{title}</Typography>
      {children}
    </Grid>
  );
}

const ContainedSection = (props: ContainedSectionProps) => {
  const { id, color, colorName, slopedBottom, slopedTop, children, sx, siblingHasSlopedBottom } = props;
  const backgroundColor = useColorFromThemePalette(colorName, color || '');
  let className: string = 'containedSection ';
  if (slopedBottom) {
    className += 'slopedBottom '
  }
  if (slopedTop) {
    className += 'slopedTop '
  }

  //If last child had a slopedBottom - need to adjust the slopedtop so they overlap

  const gridSx = {
    position: 'relative',
    marginBottom: 0 as number | string,
    top: 0 as number | string,
  }

  if (siblingHasSlopedBottom) {
    gridSx.top = 'calc(-100vw * tan(var(--angle) / 2))';
    gridSx.marginBottom = 'calc(-100vw * tan(var(--angle) / 2))';
  }

  return (
    <Grid id={id} className={className} sx={{ ...gridSx }}>
      {slopedTop && (
        <Box sx={{ width: '100%', backgroundColor: backgroundColor, height: 'calc((100vw * tan(var(--angle)) / 2))' }} />
      )}
      <Grid container sx={{ backgroundColor: backgroundColor, padding: '4rem 1rem', ...sx }}>

        <Grid sx={{ maxWidth: pageWidth + 'px', width: '100%', padding: '0', margin: '0 auto' }}>
          {children}
        </Grid>

      </Grid>
      {slopedBottom && (
        <Box sx={{ width: '100%', backgroundColor: backgroundColor, height: 'calc(100vw*(tan(var(--angle))) / 2)' }} />
      )}
    </Grid>
  );
};

const ContainedImgSection = (props: ContainedImgSectionProps) => {
  const { img, maxHeight, slopedBottom, slopedTop } = props;
  let className: string = 'imgSection ';
  if (slopedBottom) {
    className += 'slopedBottom '
  }
  if (slopedTop) {
    className += 'slopedTop '
  }
  return (
    <Box className={className} sx={{ width: '100%', height: 'auto', backgroundImage: 'url("' + img + '")', backgroundPosition: 'center', backgroundSize: 'cover' }} />
  )
}

const SectionTitle = (props: { text: string, color?: string, extraText?: ReactNode }) => {
  const { text, color, extraText } = props;
  return (
    <Grid item md={12}>
      <Typography variant="h2" color={color} textAlign={"center"} sx={{ hyphens: 'auto', wordBreak: 'break-word' }}>{text}</Typography>
      {extraText}
    </Grid>
  )
}

interface StyledButtonProps extends ButtonProps {
  text: string
}

const StyledButton = (props: StyledButtonProps) => {
  const { text, ...restProps } = props;
  return (
    <Button variant="outlined" color="primary" sx={{ padding: '0.5rem', minWidth: '30%', fontSize: '1rem', textTransform: 'inherit' }} {...restProps}>
      {text}
    </Button>
  )
}


const AnmeldenButton = () => {
  return <StyledButton onClick={() => {
    scroller.scrollTo(registrierungAnchor, {
      duration: 500,
      delay: 0,
      smooth: true,
      offset: -100
    });
  }} text="Anmelden!" />
}


function App() {

  return (
    <div id="home" className="App">
      <ScrollIndicator />
      <HeaderNavigation />
      <ContainedSection>
        <Grid container>
          <Grid item md={12} xs={12} textAlign={"center"}>
            <Typography variant="h1cardo" color="primary" sx={{ wordBreak: 'break-word', marginBottom: 0 }}>Apella Mitarbeiter-Schulung</Typography>
            <Typography variant="h1cardo" color="primary" sx={{ wordBreak: 'break-word', fontSize: '30px' }}>Thema: MSC</Typography>
            <Grid container>
              <Grid item xs={12} mb={'3rem'}>
                <Typography variant="body1" color="primary" mb={2} textAlign="center">Apella organisiert eine nächste Mitarbeiter-Schulung am 2. November 2023.</Typography>
                <Typography variant="body1" color="primary" mb={2} textAlign="center">Thema der Schulung ist das MSC, und insbesondere die Funktionen Stammdatenänderung, Abrechnung, Kunden/Verträge,
                  Formulare, Dashboard, Beraten. </Typography>
                <Typography variant="body1" color="primary" mb={2} textAlign="center">Der Referent der Schulung ist unser Kollege Matthias Sprenger. Es ist wichtig, dass alle Apella-Mitarbeiter das System
                  unserer Makler kennen und in der Lage sein, einfache Fragen zu beantworten. Darum bitten wir darum, dass alle sich anmelden.</Typography>
                <Typography variant="body1" color="primary" mb={2} textAlign="center">Die Schulung startet um 9 Uhr für die erste Gruppe und um 13.30 Uhr für wer lieber am Nachmittag kommt. Es können eventuell auch externe Teilnehmer anwesend sein.
                  Um die Gruppen gleichmäßig zu verteilen, werden pro Gruppe max. 35 Teilnehmer zugelassen.
                  Meldet Euch also schnell an um Euer Wunschtermin zu ergattern!</Typography>
              </Grid>
              <Grid item xs={12}>
                <AnmeldenButton />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ContainedSection>
      <ContainedSection color="#e5e5e5" slopedTop >
        <Grid container mb={'2rem'}>
        <StyledBox md={4} xs={12} imgSrc="./images/wann.png" title="WANN?">
            <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem', fontWeight: 'bold' }}>02. November 2023</Typography>
          </StyledBox>
          <StyledBox md={4} xs={12} imgSrc="./images/wo.png" title="WO?">
            <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem' }}>Gneisstraße 10<br />17036 Neubrandenburg<br />Seminarraum Erdgeschoss</Typography>
          </StyledBox>
          <StyledBox md={4} xs={12} imgSrc="./images/sprecher.png" title="SPRECHER">
            <Typography variant="body1" color="primary" sx={{ fontSize: '1.25rem' }}>Matthias Sprenger{/*}<br />unreadable*/}</Typography>
          </StyledBox>
        </Grid>
      </ContainedSection>
      <ContainedSection color="#1f4034" id={'anmelden'} slopedBottom >
        <SectionTitle color="common.white" text="HIER BITTE ZUR SCHULUNG ANMELDEN" />
        <Box>
          <RegistrationForm anchorName={registrierungAnchor} />
        </Box>
      </ContainedSection>
      {/*}<ContainedSection color="#fbfbfb" slopedTop>
        <Typography variant="h2" color="primary" mb={'3rem'} sx={{ fontWeight: 'bold', textAlign: 'center' }}>Sprecher</Typography>
        <Grid container sx={{ textAlign: 'center' }} flexDirection="row" alignItems={'center'} mb={'3rem'} justifyContent={'center'} spacing={10}>

          <Grid item display="flex" flexDirection="column" justifyContent={'center'}>
            <Grid item sx={{ marginBottom: '1rem' }}>
              <Box sx={{ maxWidth: '240px', height: 'auto', borderRadius: '50%' }}
                component="img"
                src="./images/matthias-crop.webp"
                alt="Matthias Sprenger"
              />
            </Grid>
            <Grid item display="flex" flexDirection="column" justifyContent={'center'}>
              <Typography variant="body1" mb={'1rem'} sx={{ fontWeight: 'bold', fontSize: '20px' }}>Matthias Sprenger</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container flexDirection="column" alignItems={'center'}>
          <Grid item id="Anmelden" textAlign={"center"}>
            <AnmeldenButton />
          </Grid>
        </Grid>
  </ContainedSection>*/}
      <ContainedImgSection img={'./images/building.webp'} maxHeight={800} slopedTop />
      
      <FooterNavigation />
    </div>
  );
}

export default App;
