import { useField } from "formik";
import { MyRadioInputGroup } from "../../Interfaces";
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormHelperText, FormLabel, Grid, Paper, Typography } from "@mui/material";


export const MultiSelectGroup = (props: MyRadioInputGroup) => {
    const { label, options, description, beforeContent, afterContent, ...inputProps } = props; 
    const [field, meta] = useField(inputProps);
    return (
            <Paper sx={{padding: '1rem'}}>
            <FormControl error={!!(meta.touched && meta.error)}>
                <FormLabel sx={{ color: 'var(--color-primary)', fontWeight: 'bold' }}>{label}</FormLabel>
                {description && <Typography variant="body1">{description}</Typography>}
                <FormGroup
                    row
                    {...field}
                >
                    {options.map((option, index) => <FormControlLabel control={<Checkbox name={inputProps.name} />} key={index} value={option.value} label={option.label} />)}
                </FormGroup>
                {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
            </FormControl>
            </Paper>
    )
}