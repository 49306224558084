import { useField } from "formik";
import { MyRadioInputGroup } from "../../Interfaces";
import { Box, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Paper, Radio, RadioGroup, Typography } from "@mui/material";

export const RadioInputGroup = (props: MyRadioInputGroup) => {
    const { label, options, description, beforeContent, afterContent, ...inputProps } = props;
    const [field, meta] = useField(inputProps);
    return (

        <Paper>
            {beforeContent}
            <Box sx={{ padding: '1rem' }}>
                <FormControl error={!!(meta.touched && meta.error)}>
                    <FormLabel sx={{ color: 'var(--color-primary)', fontWeight: 'bold' }}>{label}</FormLabel>
                    {description && <Typography variant="body1">{description}</Typography>}
                    <RadioGroup
                        row
                        {...field}
                    >
                        {options.map((option, index) => <FormControlLabel control={<Radio />} key={index} value={option.value} label={option.label} />)}
                    </RadioGroup>
                    {meta.error && <FormHelperText>{meta.error}</FormHelperText>}
                </FormControl>
            </Box>
            {afterContent}
        </Paper>
    )
}
