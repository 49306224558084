import * as Yup from 'yup';

import { ReactNode, useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography } from '@mui/material';
import { Formik, Form } from 'formik';
import { StyledButton } from '../styledButton/StyledButton';
import { ActivityGroup, InitialValues, RegistrationField, ValidationConfig, ValidationTypes } from '../../Interfaces';
import { Field } from '../Fields/Field';
import { buildYupStringSchema, fetchData } from '../../Helpers';
import { useRegistrationData } from '../../hooks/useRegistrationData';
import { Element, scroller } from 'react-scroll';
import React from 'react';

const buildInitialValuesAndValidationSchema = (registrationFields: RegistrationField[], groupedActivities: ActivityGroup[]) => {
    const initialValues: InitialValues = {};
    const validationSchema: ValidationConfig<any> = {};

    registrationFields.forEach((registrationField) => {
        if (registrationField.type !== 'text') {
            const fieldName = 'field' + registrationField.id;

            initialValues[fieldName] = '';

            const fieldValidationSchema: ValidationTypes = {
                required: registrationField.required,
                type: registrationField.type,
                min: registrationField.min,
                max: registrationField.max
            };
            validationSchema[fieldName] = buildYupStringSchema(fieldValidationSchema);
        }
    });

    groupedActivities.forEach((groupedActivity) => {
        const fieldName = 'group' + groupedActivity.blockId;
        initialValues[fieldName] = '';
        const fieldValidationSchema: ValidationTypes = {
            required: true,
            type: 'radio'
        };
        validationSchema[fieldName] = buildYupStringSchema(fieldValidationSchema);
    })

    return { initialValues, validationSchema };
};


const shouldRenderField = (registrationField: RegistrationField, values: Record<string, any>) => {
    if (registrationField.parentField) {
        if (registrationField.id === 43) {
            debugger;
        }

        if (registrationField.parentValue) {
            return values[`field${registrationField.parentField}`] === registrationField.parentValue;
        } else {
            return values[`field${registrationField.parentField}`] !== '';
        }
    }
    return true;
};

const getFieldOptions = (registrationField: RegistrationField) => {
    const defaultLanguage = registrationField.defaultLanguage;
    const options = registrationField.translations[defaultLanguage].options;

    return (
        options && Array.isArray(options)
            ? options.map((option: string) => ({ value: option, label: option }))
            : []
    );
};

const getTitle = (registrationField: RegistrationField) => {
    const defaultLanguage = registrationField.defaultLanguage;
    return registrationField.translations[defaultLanguage].title
}

const RegistrationForm = (props: { anchorName: string }) => {
    const { anchorName } = props;

    const scrollToElement = () => {
        scroller.scrollTo(anchorName, {
            duration: 5000
        });
    }

    const eventId = '10';
    const [isSynced, setIsSynced] = useState<boolean>(false);
    //const [isSynced, setIsSynced] = useState<boolean>(true);
    const [eventLanguage, setEventLanguage] = useState('');
    const { registrationFields, dataFetched, blocks, filteredActivities, groupedActivities } = useRegistrationData(eventId);
    const { initialValues, validationSchema } = buildInitialValuesAndValidationSchema(registrationFields, groupedActivities);



    const mapRegistrationFieldType = (type: string) => {
        const typeMappings = {
            'text': 'type',
            'checkbox': 'checkbox',
            'textfield': 'text',
            'emailfield': 'email',
            'radiobuttons': 'radio',
            'multivalues': 'multivalues'
        };
        return typeMappings[type as keyof typeof typeMappings] || 'text';
    };

    interface IdObject {
        fieldId: number;
        before?: JSX.Element;
        after?: JSX.Element;
    }

    const StyledContainer = (props: { children: ReactNode }) => {
        const { children } = props;
        return (
            <Paper elevation={0} sx={{ padding: '1rem 1rem 0 1rem', marginTop: '1rem' }}>
                {children}
            </Paper>
        )
    }

    const ids: IdObject[] = [{
        fieldId: 39
    }, {
        fieldId: 40
    }];

    const IdMapping = (id: number, key: 'before' | 'after', values: InitialValues): JSX.Element | null => {
        const foundId = ids.find((e) => e.fieldId === id && e.hasOwnProperty(key));

        if (foundId && foundId[key] && typeof foundId[key] !== 'undefined') {
            const cmp = foundId[key]!;
            const componentWithValues = React.cloneElement(cmp, { values: values });
            return <StyledContainer>{componentWithValues}</StyledContainer>;
        } else {
            return null;
        }
    };

    const BeforeContent = ({ id, values }: { id: number, values: InitialValues }) => {
        return IdMapping(id, 'before', values);
    };

    const AfterContent = ({ id, values }: { id: number, values: InitialValues }) => {
        return IdMapping(id, 'after', values);
    };



    return (
        <>
            <Element name={anchorName}></Element>
            {!dataFetched && (
                <div>loading Form</div>
            )}
            {dataFetched && (
                <Formik
                    initialValues={initialValues}
                    validationSchema={Yup.object(validationSchema)}
                    validateOnBlur={false}
                    onSubmit={(values, { setSubmitting }) => {

                        const { fieldValues, activityIds } = Object.keys(values).reduce(
                            (result, key) => {
                              if (key.startsWith('field')) {
                                result.fieldValues[key] = values[key];
                              } else if (key.startsWith('group')) {
                                result.activityIds.push(Number(values[key]));
                              }
                              return result;
                            },
                            { fieldValues: {} as InitialValues, activityIds: [] as Number[] }
                          );


                        const response = {
                            eventId: Number(eventId),
                            attending: true,
                            registrationfieldValues: fieldValues,
                            activityIds: activityIds
                        }
                        //https://dev.clicla.me/api/registrations/
                        fetchData('POST', '/api/registrations/', (serverResponse: any) => {
                            setSubmitting(false);
                            setIsSynced(true);
                            scrollToElement();
                        }, response);
                    }}
                >
                    {({ values, resetForm }) => {
                        if (isSynced) {
                            return (<Paper sx={{ padding: '1rem', paddingTop: '2rem' }}><Box display="flex" alignContent={"center"} textAlign="center" flexDirection={"column"}>
                                <Box mb={2}>
                                    <Typography mb={2} variant={'h2'}>Wir haben Ihre Anmeldung erhalten!</Typography>
                                    <Typography mb={2} variant={'body1'}>Prüfen Sie Ihre Mails auf Aktualisierungen.</Typography>
                                </Box>
                                <Box>
                                    <StyledButton onClick={() => {
                                        resetForm();
                                        setIsSynced(false);
                                    }}>eine weitere Registrierung</StyledButton></Box>
                            </Box></Paper>)
                        } else {
                            return (
                                <Form>
                                    <Grid container justifyContent={'center'}>
                                        {registrationFields && registrationFields.map((registrationField, index) => {

                                            const shouldRender = shouldRenderField(registrationField, values);

                                            if (!shouldRender) {
                                                values['field' + registrationField.id] = ''; // Clear the value
                                            }

                                            return shouldRender ? (
                                                <Grid item xs={12} key={index}>
                                                    <Field
                                                        fieldId={registrationField.id}
                                                        label={registrationField.translations[eventLanguage || registrationField.defaultLanguage].title}
                                                        name={'field' + registrationField.id.toString()}
                                                        type={mapRegistrationFieldType(registrationField.type)}
                                                        title={getTitle(registrationField)}
                                                        options={getFieldOptions(registrationField)}
                                                        beforeContent={<BeforeContent id={registrationField.id} values={values} />}
                                                        afterContent={<AfterContent id={registrationField.id} values={values} />}
                                                    />
                                                </Grid>
                                            ) : null; // Render null if shouldRender is false
                                        })}

                                        {/* render activities */}
                                        <Grid container mb={'1rem'}>
                                            {groupedActivities && groupedActivities.map((activityGroup, index) => {
                                                const matchingBlock = blocks.find((block) => block.id === activityGroup.blockId);
                                                const blockTitle = matchingBlock ? matchingBlock.translations.de.title : '';
                                                const blockDescription = matchingBlock ? matchingBlock.translations.de.description : '';
                                                return (
                                                    <Grid item xs={12} key={index}>
                                                        <Field
                                                            fieldId={activityGroup.blockId}
                                                            label={`${blockTitle}`}
                                                            type={'radio'}
                                                            description={blockDescription}
                                                            name={`group${activityGroup.blockId}`}
                                                            options={activityGroup.activities.map((activity) => ({
                                                                value: activity.id.toString(),
                                                                label: activity.translations.de.title,
                                                            }))}
                                                        />
                                                    </Grid>
                                                )
                                            })}
                                        </Grid>
                                        <StyledButton type="submit">
                                            <Typography variant="body1">Anmelden</Typography>
                                        </StyledButton>
                                    </Grid>
                                </Form>
                            )
                        }
                    }}
                </Formik>
            )}
        </>
    )
}

export default RegistrationForm;