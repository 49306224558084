import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { createTheme, ThemeProvider } from '@mui/material';

import { lime } from "@mui/material/colors";
import { CSSProperties } from '@mui/material/styles/createTypography';

//Extends styles for custom colors / typography variants
//custom styling
const defaultTheme = createTheme();

const h1Styling: CSSProperties = {
  fontSize: '3.875rem',
  textAlign: 'center',
  marginBottom: '3rem'
}

const bodyStyling: CSSProperties = {
  lineHeight: '1.5rem',
  fontSize: '1rem',
  fontWeight: 'normal',
  fontFamily: "'Lato',sans-serif"
}

const font = ["'Lato',sans-serif","'Cardo',sans-serif"]

const theme = createTheme({
  typography: {
    h1: h1Styling,
    h1cardo: { ...h1Styling, fontSize: '3.125rem', fontFamily: "'Cardo',sans-serif" },
    h2: {
      fontSize: '2.25rem',
      lineHeight: '2.25rem',
      fontWeight: 'normal',
      marginBottom: '2.25rem'
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 'bold',
      margin: '1.5rem'
    },
    body1: bodyStyling,
    body2: {
      ...bodyStyling,
      fontWeight: 300
    },
    fontFamily: font.join(','),
  },
  components: {
    MuiButton: {
      styleOverrides: {
        iconSizeMedium: {
          '& > *:first-of-type': {
            fontSize: '1.5rem', 
            fontWeight: 'bold',
            stroke: 'white',
            strokeWidth: 2
          },
        },
      },
    },
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          // Map the new variant to render a <h1> by default
          h1cardo: 'h1',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#333',
    },
    secondary: {
      main: '#FF6300',
      contrastText: 'white'
    },
    apellagreen: defaultTheme.palette.augmentColor({
      color: {
        main: '#1F4034',
      }
    })
  }
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <ThemeProvider theme={theme}>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </ThemeProvider>
);